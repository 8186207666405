import GlobalStorage from "@/classes/GlobalStorage";

export default class UnifiedApiManager{

	constructor() {
		switch(window.location.origin.toLowerCase()){
			case "https://mybonusright.com":
				this.baseURL = "https://api.visionlink.co/";
				break;

			case "https://demo.mybonusright.com":
				this.baseURL = "https://apidemo.visionlink.co/";
				break;

			case "https://rewards.visionlink.co":
				this.baseURL = "https://api.visionlink.co/";
				break;

			case "https://demorewards.visionlink.co":
				this.baseURL = "https://apidemo.visionlink.co/";
				break;

			default:
				this.baseURL = process.env.VUE_APP_UNIFIED_API_URL ? process.env.VUE_APP_UNIFIED_API_URL:"https://apidemo.visionlink.co/";
		}
		this._token = null;
		if(GlobalStorage.has("token")){
			this._token = GlobalStorage.load("token");
		}
		this._adminUrl = null;
		if(GlobalStorage.has("adminUrl")){
			this._adminUrl = GlobalStorage.load("adminUrl");
		}

		this._apiLoginInfo = null;
	}

	set loginInfo(o) {
		localStorage.setItem('apiLoginInfo', JSON.stringify(o));
		this._apiLoginInfo = o;
	}

	get loginInfo() {
		if (!this._apiLoginInfo) {
			let s = localStorage.getItem('apiLoginInfo');
			if (s) this._apiLoginInfo = JSON.parse(s);
		}
		return this._apiLoginInfo;
	}

	set token(v){
		GlobalStorage.save("token", v);
		this._token = v;
	}

	get token(){
		return this._token;
	}

	set adminUrl(v){
		GlobalStorage.save("adminUrl", v);
		this._adminUrl = v;
	}

	get adminUrl(){
		return this._adminUrl;
	}

	route(part){
		let nonTokens = [
			"login",
			"single-sign-on",
			"logout",
			"first-login",
			"forgot-password",
			"reset-password",
			"new-password-login"
		];
		if(nonTokens.includes(part)){
			return this.baseURL + "api/" + part;
		}
		// if(this.token){
		// 	return this.baseURL + "token/" + this.token + "/api/" + part;
		// }
		// console.warn(part + " needs a token, and none is set");
	}

	get(route){
		return window.axios.get(this.route(route), { withCredentials: true });
	}

	post(route, data){
		return window.axios.post(this.route(route), data, { withCredentials: true });
	}

}
