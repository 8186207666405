<template>
	<div class="login-page auth">
		<terms-form :display-terms="showTerms" @terms-accepted="onTermsAccepted"></terms-form>
		<div class="container">
			<div class="callout">
				<img src="../../assets/myvisionlink-rewards-light.svg" alt="VisionLink" />
				<h1>Access up-to-date plan details, forecasts, and payments.</h1>
				<standard-button @click="gotoVisionLink">Click here to learn<br />more about VisionLink</standard-button>
			</div>
		</div>
		<div class="container">
			<div v-if="showDefaultLogin" class="login-page-form card">
				<h2>Sign on to access your plan.</h2>
				<input-field v-model="email" @keyup.enter.native="login">
					Email
				</input-field>
				<password-field
					field-type="password"
					v-model="password"
					@keyup.enter.native="login"
				>
					Password
				</password-field>
				<g-recaptcha
					data-sitekey="6LcQClsiAAAAAEy1BejrR3u_AXMQ1zsXq-1cq4ja"
					data-btn-class="br-login-standard-button"
					:data-validate="evtValidate"
					:data-callback="login"
				>Login</g-recaptcha>
				<a href="#" class="forgot-link" @click="showForgot=true">Forgot Password</a>
				<div class="tou-and-pp">
					<a href="/site/terms-of-use.pdf">Terms of Use</a> <a href="/site/privacy-policy.pdf">Privacy Policy</a>
				</div>
			</div>
			<div v-if="showSingleSignOn" class="login-page-form">
				<div style="margin-bottom: 10px;">Logging you into Rewards...</div>
			</div>
			<div v-if="showForgot" class="login-page-form card">
				<input-field v-model="email" @keyup.enter.native="login">
					Email
				</input-field>
				<g-recaptcha
					data-sitekey="6LcQClsiAAAAAEy1BejrR3u_AXMQ1zsXq-1cq4ja"
					data-btn-class="br-login-standard-button"
					:data-validate="evtValidate"
					:data-callback="forgotPassword"
				>Submit</g-recaptcha>
				<a href="#" class="forgot-link" @click="showForgot=false">Back to Login</a>
				<div class="tou-and-pp">
					<a href="/site/terms-of-use.pdf">Terms of Use</a> <a href="/site/privacy-policy.pdf">Privacy Policy</a>
				</div>
			</div>
			<div v-if="showResetPassword && !showTerms" class="login-page-form login-page-reset">
				<div style="margin-bottom: 10px;">You must reset your password to continue.</div>
				<input-field v-model="email">
					Email
				</input-field>
				<password-field
					field-type="password"
					v-model="oldPassword"
				>
					Old Password
				</password-field>
				<password-field
					field-type="password"
					v-model="newPassword"
				>
					New Password
				</password-field>
				<password-field
					field-type="password"
					v-model="newPasswordConfirm"
				>
					Confirm New Password
				</password-field>
				<standard-button @click="updatePassword">Update</standard-button>
			</div>
			<vk-modal v-if="showError" :show.sync="showError">
				<vk-modal-title>Login Failed</vk-modal-title>
				<div>{{ errorMessage }}</div>
				<vk-modal-close></vk-modal-close>
			</vk-modal>
			<vk-modal v-if="showForgotConfirmation" :show.sync="showForgotConfirmation">
				<vk-modal-title>Request Submitted</vk-modal-title>
				<div>An email has been sent to provide you with further instructions. If you don't see it, please check your spam or junk folder.</div>
				<vk-modal-close></vk-modal-close>
			</vk-modal>
			<vk-modal v-if="showResetPasswordMessage" :show.sync="showResetPasswordMessage" >
				<vk-modal-title>Reset Password</vk-modal-title>
				<div>{{ passwordResetMessage }}</div>
				<vk-modal-close></vk-modal-close>
			</vk-modal>
		</div>
	</div>
</template>

<script>
import gRecaptcha from "@finpo/vue2-recaptcha-invisible";
import InputField from "@/components/elements/InputField";
import PasswordField from "@/components/elements/PasswordField";
import StandardButton from "@/components/elements/StandardButton";
import TermsForm from "./TermsForm";
export default {
    name: "BrLogin",
	components:{
		gRecaptcha,
		InputField,
		PasswordField,
		StandardButton,
		TermsForm
	},
    data() {
        return {
			email: "",
			password: "",
			showError: false,
			errorMessage: "XXX",
			showSingleSignOn: false,
			showForgot: false,
			showForgotConfirmation: false,
			showResetPassword: false,
			showResetPasswordMessage: false,
			passwordResetMessage: "",
			oldPassword: null,
			newPassword: null,
			newPasswordConfirm: null,
			showTerms: false,
			termsAccepted: false,
		}
    },
    mounted() {
	this.init();
    },
    methods: {
		init(){
			if(this.$route.path === '/single-sign-on'){
				this.showSingleSignOn = true;
				this.$apiManager.get("single-sign-on").then(
					(pack) => this.loginResults(pack),
					() => this.dataFailure(),
				);
			}
		},
		evtValidate() {
			return true;
		},
		login(token){
			let sRoute = this.$unifiedApiManager.baseURL + 'api/login';
			let oBody = { email: this.email, password: this.password };
			let sMethod = 'POST';
            let oHeaders = { 'Content-Type': 'application/json' };
			fetch(sRoute, { method: sMethod, body: JSON.stringify(oBody), headers: oHeaders })
				.then((res) => res.json())
				.then((data) => {
					this.$unifiedApiManager.loginInfo = data;
					this.$apiManager.post("login", {
						email: this.email,
						password: this.password,
						recaptchaResponse: token,
						acceptedTerms: this.termsAccepted
					}).then(
						(pack) => this.loginResults(pack),
						() => this.dataFailure(),
					);
				});
		},
		loginResults(pack){
			if(pack.data && pack.data.success){
				if(pack.data.token === 'adminUrl'){
					window.location.href = pack.data.adminUrl;
					return;
				}
				this.$apiManager.token = pack.data.token;
				if(pack.data.adminUrl){
					this.$apiManager.adminUrl = pack.data.adminUrl;
				}
				this.$apiManager.get("user").then(
					(pack) => this.userDataSuccess(pack),
					() => this.dataFailure(),
				);
			} else if(pack.data) {
				this.showSingleSignOn = false;
				switch(pack.data.errorCode){
					case "must-change-password":
						this.showResetPassword = true;
						break;
					case "must-accept-terms":
						this.showTerms = true;
						break;
					case "must-accept-terms-and-change-password":
						this.showTerms = true;
						this.showResetPassword = true;
						break;
					case "try-again":
						this.errorMessage = "Error processing request, please try again.";
						this.showError = true;
						break;
					default:
						this.errorMessage = "The email or password you provided is incorrect.";
						this.showError = true;
						break;
				}
			} else {
				this.errorMessage = "The email or password you provided is incorrect.";
				this.showError = true;
			}
		},
		userDataSuccess(pack){
			if(pack.data && pack.data.success){
				this.$dataDirectory.userData = pack.data;
				this.$router.push("/dashboard");
			} else {
				this.dataFailure();
			}
		},
		dataFailure(){
			this.errorMessage = "There was an issue communicating with the server. Please try again later.";
			this.showError = true;
		},
		forgotPassword(token){
			this.$apiManager.post("forgot-password", {
				email: this.email,
				recaptchaResponse: token
			}).then(
				(pack) => this.forgotSubmitted(pack),
				() => this.dataFailure()
			);
		},
		forgotSubmitted(pack){
			if(pack.data && pack.data.success){
				this.showForgotConfirmation = true;
			} else {
				this.errorMessage = "There was an issue communicating with the server. Please try again later.";
				this.showError = true;
			}
		},
		updatePassword(){
			if(!this.newPassword || this.newPassword.trim() === ""){
				this.passwordResetMessage = "Please provide your old password.";
				this.showResetPasswordMessage = true;
				return;
			}if(!this.oldPassword || this.oldPassword.trim() === ""){
				this.passwordResetMessage = "Please set your new password.";
				this.showResetPasswordMessage = true;
				return;
			}
			if(this.newPassword === this.newPasswordConfirm){
				this.$apiManager.post("new-password-login", {
					email: this.email,
					oldPassword: this.oldPassword,
					newPassword: this.newPassword,
					acceptedTerms: this.termsAccepted
				}).then(
					(pack) => this.passwordUpdated(pack),
					() => this.dataFailure()
				);
			} else {
				this.passwordResetMessage = "Password and password confirmation do not match.";
				this.showResetPasswordMessage = true;
			}
		},
		cancelPassword(){
			this.showResetPasswordMessage = false;
		},
		passwordUpdated(pack){
			if(pack.data && pack.data.success){
				this.$apiManager.token = pack.data.token;
				if(pack.data.adminUrl){
					this.$apiManager.adminUrl = pack.data.adminUrl;
				}
				this.$apiManager.get("user").then(
					(pack) => this.userDataSuccess(pack),
					() => this.dataFailure(),
				);
			} else {
				this.passwordResetMessage = "Unable to verify current login credentials. Please check the email (username) and try your current password again.";
				this.oldPassword = null;
				this.showResetPasswordMessage = true;
			}
		},
		onTermsAccepted(){
			this.showTerms = false;
			this.termsAccepted = true;
			if(!this.showResetPassword){
				this.login();
			}
		},
		gotoVisionLink(){
			window.open('https://visionlink.co');
		},
	},
	computed:{
		showDefaultLogin(){
			return !this.showSingleSignOn && !this.showForgot && !this.showResetPassword && !this.showTerms;
		}
	}
}
</script>
<style>
.br-login-standard-button {
	color: #ffffff;
	background-color: #479dea;
	border: none;
	border-radius: 20em;
	font-size: 14px;
	padding: 8px 25px;
	cursor: pointer;
	text-decoration: none;
	font-family: "Lexend Deca", sans-serif;
	letter-spacing: 0.08em;
	font-weight: 500;
	height: 36px;

	&:hover{
		color: #ffffff;
		background: linear-gradient(90deg, #479dea 31.5%, #de97ff);
	}
}
</style>
<style lang="scss" scoped>
.login-page{
	&-form{
		max-width: 470px;
		border-radius: 15px;
		text-align: center;
		padding: 40px;
		position: relative;
		background-color: #FFFFFF;
	}

	&-reset{
		height: 400px;
		width: 625px;
		padding: 50px 25px;
		margin: calc(50vh - 225px) auto;
	}

	.forgot-link{
		display: block;
		margin-top: 20px;
		font-size: 14px;
	}
	.tou-and-pp{
		display: flex;
		gap: 30px;
		font-size: 14px;
		border-top: 1px solid $color-divider;
		justify-content: center;
		margin-top: 20px;
		padding-top: 20px;

		a{
			width: auto !important;
		}
	}
}
</style>
